import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import styles from './Page.module.css';
import { CREATE_INQUIRY } from './graphql';

function Page1() {
  const [createInquiry, { loading }] = useMutation(CREATE_INQUIRY);
  const navigate = useNavigate();
  const [ form ] = Form.useForm();
  const onFinish = values => {
    createInquiry({
      variables: {
        data: values
      },
      onCompleted: data => navigate('/result?ref=/page1')
    });
  };

  return (
    <div className={styles.root}>
      <Typography.Title className={styles.title} level={3}>联系我们</Typography.Title>
      <Typography.Paragraph className={styles.description}>
        如果您有任何问题想向我们了解，请填写下列表单并提交，我们的咨询顾问将及时与你联系。
      </Typography.Paragraph>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.Item label="姓名" name="name" rules={[ { required: true, message: '请输入姓名' } ]}>
              <Input placeholder="请输入姓名" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="手机" name="mobile">
              <Input placeholder="请输入手机" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="公司" name="company" rules={[ { required: true, message: '请输入公司名称' } ]}>
              <Input placeholder="请输入公司名称" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="职务" name="jobTitle" rules={[ { required: true, message: '请输入公司职务' } ]}>
              <Input placeholder="请输入公司职务" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label="留言" name="message">
              <Input.TextArea placeholder="请输入要咨询的内容" rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <p style={{ textAlign: 'right' }}><Button icon={<SendOutlined />} type="primary" htmlType="submit" loading={loading}>立即提交</Button></p>
        <Form.Item name="category" initialValue={"0"} hidden><Input /></Form.Item>
      </Form>
    </div>
  );
}

export default Page1;