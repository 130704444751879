import { Button, Result } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

function PageResult() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const executed =  /\?ref=(.+)/.exec(search);
  const backTo = executed?.[1] || -1;
  console.log(search);
  return (
    <div style={{ marginTop: '5rem' }}>
      <Result
        status="success"
        title="提交成功"
        subTitle="我们的顾问团队会很快与你联系"
        extra={[
          <Button type="primary" key={0} onClick={() => navigate(backTo)}>返回</Button>
        ]}
      />
    </div>
  );
}

export default PageResult;