import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import styles from './Page.module.css';
import { CREATE_INQUIRY } from './graphql';

function Page3() {
  const [createInquiry, { loading }] = useMutation(CREATE_INQUIRY);
  const navigate = useNavigate();
  const [ form ] = Form.useForm();
  const onFinish = values => {
    createInquiry({
      variables: {
        data: values
      },
      onCompleted: data => navigate('/result?ref=/page3')
    });
  };

  return (
    <div className={styles.root}>
      <Typography.Title className={styles.title} level={3}>Contact Us</Typography.Title>
      <Typography.Paragraph className={styles.description}>
        If you have any questions you would like to know from us, please fill out the form below and submit it, and our consultants will contact you promptly.
      </Typography.Paragraph>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.Item label="Name" name="name" rules={[ { required: true, message: 'Please enter your Name' } ]}>
              <Input placeholder="Enter your Name" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Tel" name="mobile">
              <Input placeholder="Enter your Tel" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Compnay" name="company" rules={[ { required: true, message: 'Please enter your Compnay' } ]}>
              <Input placeholder="Enter your Compnay" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Position" name="jobTitle" rules={[ { required: true, message: 'Please enter your Position' } ]}>
              <Input placeholder="Enter your Position" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label="Additional Information" name="message">
              <Input.TextArea placeholder="Enter your Additional Information" rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <p style={{ textAlign: 'right' }}><Button icon={<SendOutlined />} type="primary" htmlType="submit" loading={loading}>Submit Now</Button></p>
        <Form.Item name="category" initialValue={"0"} hidden><Input /></Form.Item>
      </Form>
    </div>
  );
}

export default Page3;