import { Routes, Route } from 'react-router-dom';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import PageResult from './PageResult';
function App() {
  return (
    <Routes>
      <Route path='/page1' element={<Page1 />} />
      <Route path='/page2' element={<Page2 />} />
      <Route path='/page3' element={<Page3 />} />
      <Route path='/page4' element={<Page4 />} />
      <Route path='/result' element={<PageResult />} />
    </Routes>
  );
}

export default App;