import { Button, Col, Form, Input, Row, Typography, Upload } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import styles from './Page.module.css';
import { CREATE_INQUIRY } from './graphql';

function Page4() {
  const [createInquiry, { loading }] = useMutation(CREATE_INQUIRY);
  const navigate = useNavigate();
  const [ form ] = Form.useForm();
  const normalizeUploadEvent = e => {
    if (Array.isArray(e))
      return e;
    
    return e?.fileList;
  };
  const beforeUpload = () => false;
  const onFinish = values => {
    createInquiry({
      variables: {
        data: Object.assign({}, values, {
          cv: {
            upload: values.cv[0].originFileObj
          }
        })
      },
      onCompleted: data => navigate('/result?ref=/page4')
    });
  };
  return (
    <div className={styles.root}>
      <Typography.Title className={styles.title} level={3}>Upload Resume</Typography.Title>
      <Typography.Paragraph className={styles.description}>
        If you have a resume you would like to upload to us, please fill out the form below and submit it, and our consultants will contact you promptly.
      </Typography.Paragraph>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.Item label="Name" name="name" rules={[ { required: true, message: 'Please enter your Name' } ]}>
              <Input placeholder="Enter your Name" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Tel" name="mobile">
              <Input placeholder="Enter your Tel" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label="Resume" name="cv" rules={[ { required: true, message: 'Please select your Resume' } ]} valuePropName="fileList" getValueFromEvent={normalizeUploadEvent}>
              <Upload beforeUpload={beforeUpload}><Button type="primary" ghost>Select your Resume</Button></Upload>
            </Form.Item>
          </Col>
        </Row>
        <p style={{ textAlign: 'right' }}><Button icon={<SendOutlined />} type="primary" htmlType="submit" loading={loading}>Upload Now</Button></p>
        <Form.Item name="category" initialValue={"1"} hidden><Input /></Form.Item>
      </Form>
    </div>
  );
}

export default Page4;