import { Button, Col, Form, Input, Row, Typography, Upload } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import styles from './Page.module.css';
import { CREATE_INQUIRY } from './graphql';

function Page2() {
  const [createInquiry, { loading }] = useMutation(CREATE_INQUIRY);
  const navigate = useNavigate();
  const [ form ] = Form.useForm();
  const normalizeUploadEvent = e => {
    if (Array.isArray(e))
      return e;
    
    return e?.fileList;
  };
  const beforeUpload = () => false;
  const onFinish = values => {
    createInquiry({
      variables: {
        data: Object.assign({}, values, {
          cv: {
            upload: values.cv[0].originFileObj
          }
        })
      },
      onCompleted: data => navigate('/result?ref=/page2')
    });
  };
  return (
    <div className={styles.root}>
      <Typography.Title className={styles.title} level={3}>上传简历</Typography.Title>
      <Typography.Paragraph className={styles.description}>
        如果您有简历希望上传给我们，请填写下列表单并提交，我们的咨询顾问将及时与你联系。
      </Typography.Paragraph>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.Item label="姓名" name="name" rules={[ { required: true, message: '请输入姓名' } ]}>
              <Input placeholder="请输入姓名" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="手机" name="mobile">
              <Input placeholder="请输入手机号码" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label="简历" name="cv" rules={[ { required: true, message: '请选择简历' } ]} valuePropName="fileList" getValueFromEvent={normalizeUploadEvent}>
              <Upload beforeUpload={beforeUpload}><Button type="primary" ghost>请选择简历</Button></Upload>
            </Form.Item>
          </Col>
        </Row>
        <p style={{ textAlign: 'right' }}><Button icon={<SendOutlined />} type="primary" htmlType="submit" loading={loading}>立即上传</Button></p>
        <Form.Item name="category" initialValue={"1"} hidden><Input /></Form.Item>
      </Form>
    </div>
  );
}

export default Page2;